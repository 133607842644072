<template>
    <div class="mt-4">

        <hb-data-table-header>
            <template v-slot:description>
                Grouping profiles are generated groups defined by specific features and amenities as well as size or area tiers.
            </template>
            <template v-slot:actions>
                <hb-btn @click="$emit('add')" color="secondary" small>New Grouping Profile</hb-btn>
            </template>
        </hb-data-table-header>
        
        <hb-data-table
            :headers="headers"
            :items="spaceGroups"
            @click:row="viewGroup"
        >
            <template v-slot:item.name="{ item }">
                {{item.name}}<br />
                <span class="hb-text-light">{{item.description}}</span>
            </template>
            <template v-slot:item.num_groups="{ item }" class="small-width">
                {{  item.num_groups}}
            </template>
            <template v-slot:item.num_spaces="{ item }" style="width: 50px;">
                {{item.num_spaces}}
            </template>
            <template v-slot:item.is_default="{ item }">
                <span v-if="item.is_default == 1">
                    <hb-icon class="pl-2" mdi-code="mdi-table-actions-enable"></hb-icon>
                </span>
                <span v-else>
                    <hb-icon class="pl-2" mdi-code="mdi-table-actions-disable"></hb-icon>
                </span>
            </template>
            <template v-slot:item.actions="{ item }">
                <hb-menu
                    options
                    align-right
                >
                    <v-list>
                        <v-list-item @click="viewGroup(item)">
                            <v-list-item-title>View Grouping Profile</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="updateGroup(item, 1)">
                            <v-list-item-title>Set as Default Grouping Profile</v-list-item-title>
                        </v-list-item>
                        <v-list-item v-if="item.editable" @click="deleteGroup(item)">
                            <v-list-item-title>Delete Grouping Profile</v-list-item-title>
                        </v-list-item>
                    </v-list>
                </hb-menu>
            </template>
        </hb-data-table>
            

    </div>
</template>

<script type="text/babel">

 export default {
        name: "SpaceGroupsSettings",
        props: {
            facility_id: {
                type : String,
                default: ''
            }
        },
        data() {
            return {
                profile: {
                    id: '',
                    name: '',
                    description:'',
                }, 
 
                headers: [
                    { text: "Name", value: "name" },
                    { text: "Number of Groups", value: "num_groups", width: "200" },
                    { text: "Number of Spaces", value: "num_spaces", width: "200" },
                    { text: "Default", value: "is_default", width: "100" },
                    { text: "", value: "actions", width: "50" },  
                ],
            }
        },
        props:['spaceGroups', 'property_id'],
        methods:{
            viewGroup(item){
                this.$emit('view', item);
            },
            deleteGroup(item){
                this.$emit('delete', item);
            },
            updateGroup(item, isDefault) {
                let data = {...item}
                data.is_default = isDefault;
                this.$emit('update', data);  
            }
        }
 }

</script>