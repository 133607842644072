<template>
  <div> 
    <hb-modal v-model="isDeleteOrUpdate" show-help-link v-if="isDeleteOrUpdate" size="medium" :title="action === 'delete' ? 'Delete Grouping Profile' : 'Update Grouping Profile'" @close="closeWindow" confirmation>
      <template v-slot:content>
        <div class="py-4 px-6">
          <span v-if="action === 'delete'">
            You are about to delete the <strong>{{profileName}}</strong> grouping profile.<br /><br />
            Are you sure you want to delete this grouping profile?
          </span>
          <span v-else-if="action === 'update'">
            You are about to set the <strong>{{profileName}}</strong> as default grouping profile.<br /><br />
            Are you sure you want to set this as the default? This action will unset the currently set default profile.
          </span>
        </div>
      </template>
      <template v-slot:right-actions>
          <hb-btn :disabled="buttonLoader" :loading="buttonLoader" :color="action === 'delete' ? 'destructive' : 'primary'" @click="deleteUpdateConfirm">{{action === 'delete' ? 'Delete' : 'Update'}}</hb-btn>
      </template>
    </hb-modal>
  </div>
</template>

<script type="text/babel">
export default {
  name: "DeleteUpdateModal",
  props: {
    action: {
      type: String,
      default: ''
    },
    profileName: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      buttonLoader: false
    }
  },
  computed: {
    isDeleteOrUpdate() {
        return !!(this.action === 'delete' || this.action === 'update')
    }
  },
  watch: {
    action(value) {
      if(!value) this.buttonLoader = false;
    }
  },
  methods: {
    deleteUpdateConfirm() {
      this.buttonLoader = true;
      this.action === 'delete' ? this.$emit("deleteProfile") : this.$emit("updateProfile");
    },
    closeWindow() {
      this.$emit("close")
    }
  }
}

</script>
